import { Location } from '@angular/common';
import { Directive, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({
  selector: '[gcLocationBack]',
  standalone: true
})
export class LocationBackDirective {

  constructor(private activatedRoute: ActivatedRoute, private _router: Router, private location: Location) { }

  @HostListener('click') onClick() {
    let backUrl = ''

    if (this.activatedRoute?.snapshot?.routeConfig?.data && this.activatedRoute.snapshot.routeConfig.data['backUrl'])
      backUrl = this.activatedRoute.snapshot.routeConfig.data['backUrl']

    if (backUrl) {
      this._router.navigate([backUrl])

      return
    }

    this.location.back()
  }
}
